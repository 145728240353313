import { CommonModule } from '@angular/common';
import { Component, OnDestroy, OnInit } from '@angular/core';
import { RouterModule } from '@angular/router';
import { LoadingService } from '@shared/utils';
import { UiLibraryModule } from '@ui-library/ui-library.module';
import { UpscoreAdminSharedModule } from '@upscore-admin/shared';
import { Subject, takeUntil } from 'rxjs';

@Component({
    standalone: true,
    imports: [RouterModule, UiLibraryModule, CommonModule, UpscoreAdminSharedModule],
    selector: 'upscore-admin-root',
    templateUrl: './app.component.html',
    styleUrls: ['./app.component.scss'],
})
export class AppComponent implements OnDestroy, OnInit {
    public loading = false;
    public loadingText$ = this.loadingService.loadingText.asObservable();
    private unsubscribe$ = new Subject<void>();

    constructor(private loadingService: LoadingService) {}

    ngOnInit(): void {
        this.loadingService
            .loading()
            .pipe(takeUntil(this.unsubscribe$))
            .subscribe((value: boolean) => {
                this.loading = value;
            });
    }

    ngOnDestroy(): void {
        this.unsubscribe$.next();
    }
}
