import { CommonModule } from '@angular/common';
import { Component } from '@angular/core';
import { RouterModule } from '@angular/router';
import { UpscoreAdminSharedModule } from '@upscore-admin/shared';

@Component({
    selector: 'triply-feature-shell',
    standalone: true,
    imports: [CommonModule, UpscoreAdminSharedModule, RouterModule],
    templateUrl: './feature-shell.component.html',
    styleUrls: ['./feature-shell.component.scss'],
})
export class FeatureShellComponent {}
