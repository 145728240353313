import { Component } from '@angular/core';
import { AuthService } from '@auth/data-access-auth';

@Component({
    selector: 'upscore-admin-nav-bar',
    templateUrl: './nav-bar.component.html',
    styleUrls: ['./nav-bar.component.scss'],
})
export class NavBarComponent {
    constructor(private authService: AuthService) {}

    public logout() {
        this.authService.logout();
    }
}
