import {
    HTTP_INTERCEPTORS,
    HttpErrorResponse,
    provideHttpClient,
    withInterceptorsFromDi,
} from '@angular/common/http';
import { APP_INITIALIZER, ErrorHandler, importProvidersFrom } from '@angular/core';
import { ApplicationConfig } from '@angular/platform-browser';
import { provideAnimations } from '@angular/platform-browser/animations';
import { Router, provideRouter, withEnabledBlockingInitialNavigation } from '@angular/router';
import { AuthModule } from '@auth/feature-auth';
import {
    BrowserTracing,
    Replay,
    TraceService,
    createErrorHandler,
    init,
    routingInstrumentation,
} from '@sentry/angular-ivy';
import {
    ENVIRONMENT,
    JwtInterceptor,
    LoadingInterceptor,
    ResponseErrorInterceptor,
} from '@shared/utils';
import { ApiModule } from '@upscore-admin/api';
import { UpscoreAdminApiWrapperService, UpscoreAdminUserDataService } from '@upscore-admin/shared';
import { ToastrModule } from 'ngx-toastr';
import { environment } from '../environments/environment';
import { appRoutes } from './app.routes';

init({
    dsn: environment.sentryDsn,
    environment: environment.name,
    attachStacktrace: true,
    tracesSampleRate: 1.0,
    replaysOnErrorSampleRate: 1.0,
    integrations: [
        new BrowserTracing({
            routingInstrumentation: routingInstrumentation,
        }),
        new Replay(),
    ],
    beforeSend(event, hint) {
        if (
            hint.originalException != undefined &&
            hint.originalException instanceof HttpErrorResponse
        ) {
            if (event?.exception?.values?.[0] != undefined) {
                // save message in exception value
                event.exception.values[0].value = hint.originalException.message as string;
            }

            const frames = event?.exception?.values?.[0]?.stacktrace?.frames;

            if ('code' in hint.originalException.error && frames && frames.length > 0) {
                // add error code as function param to the last frame
                frames[frames.length - 1].function = hint.originalException.error.code as string;
            }

            // store error object as extra
            event.extra = hint.originalException.error;

            // add requests string
            event.fingerprint = ['{{ default }}', event.exception?.values?.[0].value || ''];

            // Here we add the event_id to the error object so we can track it in the frontend
            (hint.originalException as HttpErrorResponse & { event_id?: string }).event_id =
                event.event_id;
        }

        return event;
    },
});

export const appConfig: ApplicationConfig = {
    providers: [
        provideRouter(appRoutes, withEnabledBlockingInitialNavigation()),
        provideHttpClient(withInterceptorsFromDi()),
        provideAnimations(),
        importProvidersFrom(
            ApiModule.forRoot({ rootUrl: `${environment.apiUrl}` }),
            AuthModule.forRoot({
                apiWrapperClass: UpscoreAdminApiWrapperService,
                userDataServiceClass: UpscoreAdminUserDataService,
            }),
            ToastrModule.forRoot({
                preventDuplicates: true,
                countDuplicates: true,
            }),
        ),
        { provide: HTTP_INTERCEPTORS, useClass: LoadingInterceptor, multi: true },
        { provide: HTTP_INTERCEPTORS, useClass: JwtInterceptor, multi: true },
        { provide: HTTP_INTERCEPTORS, useClass: ResponseErrorInterceptor, multi: true },
        { provide: ENVIRONMENT, useValue: environment },
        {
            provide: ErrorHandler,
            useValue: createErrorHandler({
                showDialog: false,
                extractor(error: unknown, defaultExtractor: (error: unknown) => unknown): unknown {
                    if (
                        error != null &&
                        typeof error === 'object' &&
                        'rejection' in error &&
                        error['rejection'] instanceof HttpErrorResponse
                    ) {
                        // incase a promise fails with an HttpErrorResponse
                        return error['rejection'];
                    } else if (error instanceof HttpErrorResponse) {
                        return error;
                    } else {
                        return defaultExtractor(error);
                    }
                },
            }),
        },
        {
            provide: TraceService,
            deps: [Router],
        },
        {
            provide: APP_INITIALIZER,
            useFactory: () => () => undefined,
            deps: [TraceService],
            multi: true,
        },
    ],
};
