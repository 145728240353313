import { Injectable } from '@angular/core';
import { AbstractUserDataService, FeatureFlag } from '@shared/utils';
import { AdminUserInfo } from '@upscore-admin/api';
import { Observable, of } from 'rxjs';

@Injectable({
    providedIn: 'root',
})
export class UpscoreAdminUserDataService extends AbstractUserDataService<AdminUserInfo> {
    public showFeature(feature: FeatureFlag): boolean {
        // feature does not exist on the user object in employee-view yet
        return false;
    }
    public showFeature$(feature: FeatureFlag): Observable<boolean> {
        // feature does not exist on the user object in employee-view yet
        return of(false);
    }
    public isUserType(userType: 'PREMIUM' | 'LIGHT' | 'VIEW_ONLY'): boolean {
        // userType does not exist on the user object in employee-view yet
        return false;
    }
    public isUserType$(userType: 'PREMIUM' | 'LIGHT' | 'VIEW_ONLY'): Observable<boolean> {
        // userType does not exist on the user object in employee-view yet
        return of(false);
    }
}
